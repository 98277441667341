import React from "react"
import contactFormCss from "./contactform.module.css"

export default class contactForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  render() {
    const { status } = this.state
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/myydrlbv"
        method="POST"
      >
        <div className={contactFormCss.formGroup}>
          <label htmlFor="Nombre">Nombre:</label>
          <input type="text" name="Nombre" id="Nombre" required="required"></input>
        </div>
        <div className={contactFormCss.formGroup}>
          <label htmlFor="Email">Email:</label>
          <input type="email" name="Email" id="Email" required="required"></input>
        </div>
        <div className={contactFormCss.formGroupDouble}>
          <div className={contactFormCss.formGroup}>
            <label htmlFor="Telefono">Teléfono:</label>
            <input type="number" name="Telefono" id="Telefono" min="0" required="required"></input>
          </div>
          <div className={contactFormCss.formGroup}>
            <label htmlFor="Empresa">Empresa:</label>
            <input type="text" name="Empresa" id="Empresa"></input>
          </div>
        </div>
        <div className={contactFormCss.formGroup}>
          <label htmlFor="Estado">Estado:</label>
          <input type="text" name="Estado" id="Estado" required="required"></input>
        </div>
        <div className={contactFormCss.formGroup}>
          <label htmlFor="Direccion">Dirección:</label>
          <input type="text" name="Direccion" id="Direccion" required="required"></input>
        </div>
        <div className={contactFormCss.formGroup}>
          <label htmlFor="diseno">¿Cuál es tu área de interés?:</label>
          <label htmlFor="diseno">
            <input type="radio" name="Area" id="diseno" value="Diseño"  required="required"></input> Diseño
          </label>
          <label htmlFor="desarrollo">
            <input type="radio" name="Area" id="desarrollo" value="Desarrollo"  required="required"></input> Desarrollo
          </label>
        </div>
        <div className={contactFormCss.formGroup}>
          <label htmlFor="Mensaje">Comentarios:</label>
          <textarea name="Mensaje" id="Mensaje"  required="required"></textarea>
        </div>
        {status === "SUCCESS" ? <p className={contactFormCss.exito}>Gracias! Ya recibimos tu mensaje. :)</p> : <button>contáctanos</button>}
        {status === "ERROR" && <p>Ooops! Ocurrió un error Perdónanos! ¿Lo intentamos de nuevo :)?</p>}
      </form>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}
