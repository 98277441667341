import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import bannerCss from "./banner.module.css"

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "acercade.png" }) {
        childImageSharp {
          fixed(width: 700) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img fixed={data.file.childImageSharp.fixed} className={bannerCss.img} />
  )
}

export default Banner
