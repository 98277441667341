import React, { Component } from "react"
import Header from "./header/header"
import layoutCss from "./layout-large.module.css"
import Messenger from "../components/messenger/messenger"

class LayoutLarge extends Component {
  render() {
    return (
      <>
        <Header siteTitle={this.props.siteTitle} />
        <div className={layoutCss.container}>
          <div>{this.props.children}</div>
        </div>
        
        <Messenger></Messenger>
      </>
    )
  }
}

export default LayoutLarge
