import React, { Component } from "react"
import LayoutLarge from "../components/layout-large"
import acercadeCss from "../css/acercade.module.css"
import Banner from "../components/images/acercade/banner/banner"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm/contactform"
import { Link } from "gatsby"
import LogoRosa from "../images/logo-rosa.svg"

class Acerca extends Component {
  render() {
    return (
      <div className={acercadeCss.background}>
        <Link to="/" className={acercadeCss.logoRosa}>
          <img src={LogoRosa} alt="Logotipo de engrane version rosa" />
        </Link>
        <LayoutLarge siteTitle="Home">
          <SEO title="Acerca De Engrane" />
          <div className={acercadeCss.container}>
            <h1 className={acercadeCss.titulo}>
              CAMBIANDO TU FORMA DE VER EL MUNDO
            </h1>
            <div>
              <p className={acercadeCss.acercadetext}>
                Engrane, es una agencia de diseño multidisciplinaria que se
                encarga de aspectos tanto de diseño gráfico, desarrollo web y
                social media con el objetivo de ayudar a todas aquellas marcas
                del área de industria a poder ser conocidas de mejor forma y el
                poder saber llega a su consumidor meta con una estrategia eficaz
                e innovadora.
              </p>
              <a href="#mail" className={acercadeCss.boton}>
                contáctanos
              </a>
            </div>
          </div>
          <div className={acercadeCss.banner}>
            <Banner></Banner>
          </div>
          <div className={acercadeCss.containerFull}>
            <div className={acercadeCss.row}>
              <div>
                <h2>Development</h2>
                <p>
                  <Link to="/Develop/Web" className={acercadeCss.service}>
                    Desarrollo de sitio web
                  </Link>
                </p>
                <p>
                  <Link to="/Develop/Mobile" className={acercadeCss.service}>
                    aplicaciones moviles
                  </Link>
                </p>
                <p>
                  <Link to="/Develop/Alexa" className={acercadeCss.service}>
                    comandos alexa
                  </Link>
                </p>
                <p>
                  <Link to="/Develop/Excel" className={acercadeCss.service}>
                    automatización de <br />
                    procesos con <br />
                    marcos de excel
                  </Link>
                </p>
                <p>
                  <Link to="/Develop/ChatBot" className={acercadeCss.service}>
                    chat bot
                  </Link>
                </p>
                <p>
                  <Link to="/Develop/DataBase" className={acercadeCss.service}>
                    {" "}
                    administración de <br />
                    base de datos
                  </Link>
                </p>
              </div>
              <div>
                <h2>Design</h2>
                <p>
                  <Link to="/Design/Marca" className={acercadeCss.service}>
                    branding
                  </Link>
                </p>
                <p>
                  <Link to="/Design/Empaque" className={acercadeCss.service}>
                    empaque
                  </Link>
                </p>
                <p>
                  <Link to="/Design/Fotografia" className={acercadeCss.service}>
                    fotografía
                  </Link>
                </p>
                <p>
                  <Link to="/Design/Video" className={acercadeCss.service}>
                    producción de video
                  </Link>
                </p>
              </div>
            </div>
            <div className={acercadeCss.socialContainer}>
              <h2 className={acercadeCss.socialNav}>Conocenos</h2>
              <a
                href="https://www.facebook.com/Engrane-creativo-688342724879971/"
                className={acercadeCss.socialItem}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                </svg>
              </a>
              <a
                href="https://instagram.com"
                className={acercadeCss.socialItem}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a
                href="https://youtu.be"
                className={acercadeCss.socialItem}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                </svg>
              </a>
            </div>
          </div>
          <section id="mail" className={acercadeCss.fondoblanco}>
            <div className={acercadeCss.mailcontainer}>
              <div>
                <h2 className={acercadeCss.titleMail}>say ¡hello!</h2>
                <p className={acercadeCss.correo}>
                  <a href="mailto:contacto@engrane.com">contacto@engrane.com</a>
                </p>
                <div className={acercadeCss.contactContainer}>
                  <p className={acercadeCss.contactoTexto}>
                    <span>Puebla, México</span> <br />
                    <a
                      href="https://goo.gl/maps/zds4TTnqiEoq7ffEA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Avenida Moratilla 67-2, <br />
                      Rancho Moratilla. 72110.
                    </a>
                  </p>

                  <p className={acercadeCss.contactoTexto}>
                    <span>Teléfonos:</span> <br />
                    <a href="tel:2226029376">(222) 602.93.76</a>
                    <br />
                    <a href="tel:2223799409">(222) 379.94.09</a>
                  </p>
                </div>
                <div className={acercadeCss.contactImage}>
                  <Banner className={acercadeCss.contactImage}></Banner>
                </div>
              </div>
              <div>
                <h2 className={acercadeCss.titleMail}>contact</h2>
                <ContactForm></ContactForm>
              </div>
            </div>
          </section>
          <footer className={acercadeCss.footer}>
            <p>
              {new Date().getFullYear()} Engrane :: Todos los derechos
              reservados.
            </p>
          </footer>
        </LayoutLarge>
      </div>
    )
  }
}

export default Acerca
